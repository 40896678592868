import Image from 'next/image';
import errorImage from '../../public/error/error.jpg';

export default function CustomError({ errorCode, errorMessage }) {
  return (
    <div
      data-cy-error
      data-cy-error-code={errorCode.includes('404') ? 404 : 500}
      className="container"
    >
      <div className="error-page">
        <h1>{errorCode}</h1>
        <p>{errorMessage}</p>
      </div>
      <div className="errorImage">
        <Image layout="fill" objectFit="cover" src={errorImage} />
      </div>
      <style jsx>{`
        .container {
          position: relative;
        }
        h1 {
          font-weight: 100;
          font-size: 48px;
          text-align: center;
        }
        p {
          text-align: center;
          font-size: 18px;
          font-weight: 100;
        }
        .error-page {
          position: absolute;
          top: 30%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        }
        .errorImage {
          height: 100vh;
          width: 100vw;
        }
      `}</style>
      <style global jsx>{`
        body {
          margin: 0;
          padding: 0;
        }
      `}</style>
    </div>
  );
}
